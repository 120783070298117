<template>
  <!-- 直播首页 -->
  <div id="live">
    <div class="livecontainer">
      <div class="bannerArea">
        <img src="@/assets/live/livebanner.png" class="banner" alt="" />
        <div class="containerText">
          <div class="title">北美面经直播</div>
          <div class="subtitle">
            只要你写，我们来答
            <br />
            旨在帮助求职者解答面经或面经题
            <br />
            每周直播提供面经题的参考答案和核心代码
          </div>
          <el-button round type="primary" @click="$router.push('/liveRoom')"
            >进入直播间</el-button
          >
        </div>
        <div class="livePreview">
          <div class="v-icon" @click="videoVisible = true"></div>
          <img src="@/assets/live/livePreview.png" alt="" />
        </div>
      </div>
      <div class="mainArea">
        <div class="interviewList">
          <div class="companyFilter">
            <el-autocomplete
              prefix-icon="el-icon-search"
              v-model="searchCompanyKey"
              :fetch-suggestions="querySearch"
              :trigger-on-focus="false"
              placeholder="按公司名（英文）搜索"
              :popper-append-to-body="false"
              @input="searchLodash"
              @select="selectCompany"
              @keydown.native="companyId = ''"
              @keydown.native.enter="selectDefaultCompany"
              style="width: 400px"
            >
              <template slot-scope="{ item }" v-if="companys.length > 0">
                {{ item.name }}
              </template>
            </el-autocomplete>
          </div>
          <div class="contentList">
            <div
              :class="['ms-cell-interview']"
              v-for="(item, index) in contentArray"
              :key="index"
            >
              <div
                v-if="item.type && item.type.value == 2"
                @click="viewExps(item.content.id, item.id)"
                style="cursor: pointer"
              >
                <div class="ms-cell-hd">
                  <div class="ms-type" style="max-width: 70%">
                    <span
                      v-for="(label, lIndex) in item.content.labels"
                      :key="lIndex"
                      :class="[
                        lIndex == 0
                          ? 'orange'
                          : lIndex == 1
                          ? 'red'
                          : lIndex == 2
                          ? 'green'
                          : lIndex == 3
                          ? 'blue'
                          : '',
                      ]"
                    >
                      {{ label }}
                      <span style="padding: 0 5px">{{
                        lIndex == item.content.labels.length - 1 ? "" : "|"
                      }}</span></span
                    >
                    <div class="listType">{{ item.type.name }}</div>
                  </div>
                  <div class="ms-user">
                    <div class="ms-user-avatar-s" v-if="item.avatar">
                      <img :src="item.avatar" />
                    </div>
                    <div class="ms-user-avatar-s" v-else>
                      <i class="el-icon-user-solid"></i>
                    </div>
                    <div class="ms-user-name" v-if="item.username">
                      {{ item.username }}
                    </div>
                    <div class="ms-user-name" v-else>匿名用户</div>
                    <div class="ms-user-time">{{ item.createTime }}</div>
                  </div>
                </div>
                <div class="ms-cell-tt">
                  {{ item.content.title
                  }}<el-tag size="mini">{{ item.content.questions }}题</el-tag>
                </div>
                <div class="ms-cell-ft">
                  <div
                    class="ms-cell-desc ms-cell-ft"
                    v-html="item.content.interviewAfter"
                  ></div>
                  <div class="ms-cell-data">
                    <span
                      style="
                        color: #0a7aff;
                        margin-right: 12px;
                        font-size: 12px;
                      "
                      @click.stop="alsoAsk(item.content.id, 0)"
                      >我想问({{ item.content.sameTopic }})</span
                    >
                    <el-button type="text" size="mini"
                      ><span
                        class="iconfont icon-liulan"
                        style="margin-right: 4px"
                      ></span
                      >{{ item.pageViews }}</el-button
                    >
                    <el-button type="text" size="mini"
                      ><span
                        class="iconfont icon-dianzan"
                        style="margin-right: 4px"
                      ></span
                      >{{ item.likeNums }}</el-button
                    >
                    <el-button type="text" size="mini"
                      ><span
                        class="iconfont icon-huifu"
                        style="margin-right: 6px"
                      ></span
                      >{{ item.commentNums }}</el-button
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="item.type && item.type.value == 3"
                @click.stop="viewQuestions(item.content.id, item.id)"
                style="cursor: pointer"
              >
                <div class="answers" style="color: #fa6400">
                  <div class="ms-cell-hd">
                    <div>
                      <span style="vertical-align: top; margin-right: 12px">{{
                        item.content.companyName
                      }}</span>

                      <div
                        style="
                          color: #34c759;
                          background: rgba(52, 199, 89, 0.1);
                          border-radius: 1px;
                          padding: 2px 8px;
                          vertical-align: top;
                          display: inline-block;
                          font-size: 12px;
                        "
                        v-if="item.content.isExplain"
                      >
                        已解答
                      </div>
                      <div class="listType">{{ item.type.name }}</div>
                    </div>
                    <div class="ms-user">
                      <div class="ms-user-avatar-s" v-if="item.avatar">
                        <img :src="item.avatar" />
                      </div>
                      <div class="ms-user-avatar-s" v-else>
                        <i class="el-icon-user-solid"></i>
                      </div>
                      <div class="ms-user-name" v-if="item.username">
                        {{ item.username }}
                      </div>
                      <div class="ms-user-name" v-else>匿名用户</div>
                      <div class="ms-user-time">{{ item.createTime }}</div>
                    </div>
                  </div>

                  <div
                    style="font-size: 14px; margin-top: 12px; line-height: 24px"
                  >
                    <div
                      style="
                        display: inline-block;
                        width: 60%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: #292b2d;
                        vertical-align: top;
                      "
                      class="renderContent"
                      v-html="item.content.interviewQuestions"
                    ></div>
                    <div class="ms-cell-data" style="float: right">
                      <span
                        style="
                          color: #0a7aff;
                          margin-right: 12px;
                          font-size: 12px;
                        "
                        @click.stop="alsoAsk(item.content.id, 1)"
                        >我想问({{ item.content.sameTopic }})</span
                      >
                      <el-button type="text" size="mini"
                        ><span
                          class="iconfont icon-liulan"
                          style="margin-right: 4px"
                        ></span
                        >{{ item.pageViews }}</el-button
                      >
                      <el-button type="text" size="mini"
                        ><span
                          class="iconfont icon-dianzan"
                          style="margin-right: 4px"
                        ></span
                        >{{ item.likeNums }}</el-button
                      >
                      <el-button type="text" size="mini"
                        ><span
                          class="iconfont icon-huifu"
                          style="margin-right: 4px"
                        ></span
                        >{{ item.commentNums }}</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-pagination
            style="padding: 20px; text-align: center"
            :current-page.sync="currentPage"
            :total="total"
            layout="prev, pager, next"
            :page-size="20"
            @current-change="getLiveContentList"
          >
          </el-pagination>
        </div>
        <div class="asideArea">
          <div class="aside-rt">
            <div class="operateButton" @click="$router.push('/interviewEdit')">
              <i class="el-icon-edit-outline"></i>发面经
              <div class="tip">面试经验，完整面试流程</div>
            </div>
          </div>
          <div class="aside-rt">
            <div class="operateButton" @click="askQuestions">
              <i class="el-icon-search"></i>问面经题
              <div class="tip">面经不会，提问题，我们做答案</div>
            </div>
          </div>
          <SideAdvertisement></SideAdvertisement>
        </div>
      </div>
    </div>
    <div class="emailSubscribe" @click="openSubscribe">
      <img src="@/assets/live/subscribe.gif" alt="" />
    </div>
    <createQuestion
      :visible="questionVisible"
      @changeCreateQuestionVisible="(val) => (questionVisible = val)"
      @updateQuestionList="getLiveContentList"
    ></createQuestion>
    <emailSubscribe
      :visible="emailSubscribeVisible"
      @changeVisible="(val) => (emailSubscribeVisible = val)"
    ></emailSubscribe>
    <liveVideo
      v-if="videoVisible"
      @closeVideo="videoVisible = false"
    ></liveVideo>
  </div>
</template>
<script>
import { getLiveContentList } from "@/service/live";
import { getCourseBanner } from "@/service/index";
import { browse, searchCompanys, alsoAsk } from "@/service/company";
import { mapState } from "vuex";
import createQuestion from "../interview/createQuestion";
import emailSubscribe from "./emailSubscribe";
import liveVideo from "./liveVideo";
import SideAdvertisement from "@/components/sideAdvertisement";
import _ from "lodash";
export default {
  name: "Live",
  components: {
    createQuestion,
    emailSubscribe,
    liveVideo,
    SideAdvertisement,
  },
  data() {
    return {
      currentPage: 1,
      total: 0,
      qrcodeUrl: "",
      searchCompanyKey: "",
      companys: [],
      companyId: "",
      contentArray: [],
      questionVisible: false,
      emailSubscribeVisible: false,
      videoVisible: false,
    };
  },
  mounted() {
    getCourseBanner(8).then((res) => {
      this.qrcodeUrl = res.result[0].imageUrl;
    });
    this.getLiveContentList();
  },
  methods: {
    querySearch(queryString, cb, type) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.companys);
      }, 1000);
    },
    searchLodash: _.debounce(function () {
      this.searchCompanys();
    }, 1000),
    searchCompanys() {
      searchCompanys(this.searchCompanyKey).then((res) => {
        if (res.success) {
          this.companys = res.result;
        }
      });
    },
    selectCompany(option) {
      this.searchCompanyKey = option.name;
      this.companyId = option.id;
    },
    selectDefaultCompany() {
      if (this.companys.length > 0) {
        this.searchCompanyKey = this.companys[0].name;
        this.companyId = this.companys[0].id;
      } else {
        this.companyId = "";
      }
    },
    blurEvents() {
      if (this.companys.length > 0) {
        this.searchCompanyKey = this.companys[0].name;
        this.companyId = this.companys[0].id;
      } else {
        this.companyId = "";
      }
    },
    getLiveContentList(page) {
      if (page) {
        this.currentPage = page;
      } else {
        this.currentPage = 1;
      }
      getLiveContentList({
        companyId: this.companyId,
        current: this.currentPage,
        size: 20,
      }).then((res) => {
        if (res.success) {
          this.contentArray = res.result.records;
          this.total = res.result.total;
          this.$nextTick(() => {
            const renderArray = document.querySelectorAll(".renderContent");
            for (let i = 0; i < renderArray.length; i++) {
              let picArray = renderArray[i].querySelectorAll("img");
              for (let j = 0; j < picArray.length; j++) {
                let newNode = document.createTextNode("[图片]");
                console.log(picArray[j]);
                picArray[j].parentNode.replaceChild(newNode, picArray[j]);
              }
            }
          });
        }
      });
    },
    viewExps(id, postsId) {
      const data = { superId: id, superType: 1 };
      browse(postsId).then((res) => {
        if (res.success) {
          this.$router.push("/interviewDetail?id=" + id);
        }
      });
    },
    viewQuestions(id, postsId) {
      const data = { superId: id, superType: 5 };
      browse(postsId).then((res) => {
        if (res.success) {
          this.$router.push("/questionDetail?id=" + id);
        }
      });
    },
    alsoAsk(id, type) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      alsoAsk(id, type).then((res) => {
        if (res.success) {
          this.$message.success("提问成功！");
          this.getLiveContentList(this.currentPage);
        } else {
          this.$message.info("您已提过问");
        }
      });
    },
    askQuestions() {
      console.log(this.$router);
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.questionVisible = true;
    },
    openSubscribe() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.emailSubscribeVisible = true;
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {
    companyId(val) {
      if (val || val == "") {
        this.getLiveContentList();
      }
    },
  },
};
</script>
<style scoped lang="scss">
#live {
  width: 100%;
  background: #f8f8f8;
  .livecontainer {
    width: 1200px;
    margin: 0 auto;
  }
}
.bannerArea {
  width: 100%;
  position: relative;
  .banner {
    width: 100%;
    vertical-align: top;
  }
  .containerText {
    position: absolute;
    color: #fff;
    top: 20%;
    left: 6%;
    .title {
      font-size: 32px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 48px;
    }
    .subtitle {
      margin-top: 24px;
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      margin-bottom: 32px;
    }
  }
  .livePreview {
    position: absolute;
    right: 7.25%;
    z-index: 9;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    img {
      vertical-align: top;
    }
  }
}
.emailSubscribe {
  position: fixed;
  bottom: 180px;
  right: 80px;
  z-index: 99;
  img {
    vertical-align: top;
    width: 100px;
    cursor: pointer;
  }
}
.mainArea {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 6px;
  .interviewList {
    width: 900px;
    .companyFilter {
      padding: 12px 30px;
      background: #fff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }
  }
  .asideArea {
    width: 294px;
    margin-left: 6px;
    .aside-rt {
      margin-bottom: 6px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      img {
        vertical-align: top;
      }
      .qrcode-container {
        padding: 24px;
        text-align: center;
        .title {
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #34495e;
          line-height: 21px;
          margin-bottom: 18px;
        }
        .qrcode {
          width: 100px;
          height: 100px;
          vertical-align: top;
          border: 2px solid #0075f6;
          border-radius: 2px;
        }
      }
      .operateButton {
        padding: 24px;
        text-align: center;
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #34495e;
        line-height: 16px;
        cursor: pointer;
        i {
          margin-right: 6px;
        }
      }
      .tip {
        font-size: 12px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #999999;
        line-height: 18px;
        margin-top: 12px;
      }
    }
  }
}

.contentList {
  margin-top: 6px;
}
.ms-cell-interview {
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-bottom: 10px;
  padding: 20px 30px;
  &:nth-child(2n) {
    background-color: rgba(242, 242, 242, 1);
  }
}

.ms-type {
  font-size: 12px;
  color: #34495e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.listType {
  display: inline-block;
  background: rgba(250, 100, 0, 0.2);
  border-radius: 2px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #fa6400;
  line-height: 18px;
  padding: 2px 8px;
  margin-left: 12px;
}
.orange {
  color: #fa6400 !important;
}

.blue {
  color: #2772db !important;
}

.green {
  color: #2ec1ac !important;
}

.red {
  color: #e02020 !important;
}
.ms-type span {
  padding: 0;
}

.ms-type span:first-child {
  padding-left: 0;
}

.ms-user {
  display: flex;
  align-items: center;
  line-height: 18px;
}

.ms-user-name {
  color: #0075f6;
  font-size: 12px;
  padding: 0 5px;
}

.ms-user-time {
  font-size: 12px;
  color: #000000;
  opacity: 0.5;
}

.ms-user-avatar {
  font-size: 0;
}

.ms-user-avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.ms-user-avatar-s img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  vertical-align: top;
}

.ms-cell-hd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.ms-cell-tt {
  width: 73%;
  color: #34495e;
  font-size: 14px;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ms-cell-tt .el-tag {
  border-color: #b3f2ff;
  background-color: #b3f2ff;
  font-size: 12px;
  color: #34495e;
  border-radius: 0;
  padding: 0 6px;
  margin-left: 12px;
}

.ms-cell-desc {
  width: 70%;
  font-size: 12px;
  color: #828282;
  line-height: 18px;
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* 超出n行这里写n */
  -webkit-box-orient: vertical;
}

.ms-cell-data .el-button--text .iconfont {
  font-size: 12px;
  color: #34495e;
}

.ms-cell-data .el-button--text {
  font-size: 12px;
  color: #34495e;
  padding: 0;
}

.ms-cell-data .el-button + .el-button {
  margin-left: 20px;
}

.ms-cell-ft {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.v-icon {
  background: url(../../assets/live/play.png) center no-repeat;
  width: 60px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: change 0.71s ease-in-out infinite alternate;
  z-index: 2;
  cursor: pointer;
  border-radius: 50%;
}
@keyframes change {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>